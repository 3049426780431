* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  line-height: 1.25;
}

a,
a:visited {
  color: #165666;
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #eff4ff;
  border-bottom: 1px solid rgb(131, 131, 131);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transform: translateY(0%);
  transition: transform .25s;
}

header.about {
  position: relative;
}

header.translate {
  transform: translateY(-100%);
  transition: transform .25s;
}

.title-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}


header h1 {
  padding: 0px 8px;
  border: 3px solid;
  box-shadow: 3px 3px rgb(0 0 0 / 40%);
  font-size: 28px;
  font-weight: bold;
  margin-right: 24px;
  color: #000;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

/*
    TODO: solve the problem of having to update media queries every time the content of the header changes
    Possiblities:
      1. just always set the margin on the <main> element with JS based on the height of the header?
      something like :
    window.addEventListener('resize', function(){
      document.querySelector('main').style.marginTop = document.querySelector("header").clientHeight + 'px';
      })
      ... with a similar function firing when the page first loads.

      2. Something else? Can CSS do this on its own, and not break if the user changes the default font, lineheight, spacing, etc etc or the content itself is changed?
 */


section {
  padding: 10px;
}

#transcript {
  font-size: 26px;
  margin-bottom: 40px;
}


.listen-toggle {
  display: flex;
  padding: 10px;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  height: 40px;
}

#start-listen {
  background-color: rgb(1, 155, 1);
}

#start-listen:hover {
  background-color: rgb(0, 150, 0);
}

#stop-listen {
  background-color: rgb(192, 0, 0);
}

#stop-listen:hover {
  background-color: rgb(150, 0, 0);
}

.interim {
  font-size: 22px;
  color: #555;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 8px;
  width: 100%;
  border-top: 1px solid gray;
  position: fixed;
  min-height: 2em;
}

.snippet {
  outline: none;
  display: inline-block;
  padding: 0 0.13em;
  border-radius: 2px;
}

@keyframes bgFlash {
  0% {
    background: rgba(192, 192, 255, 0.5);
  }

  100% {
    opacity: rgba(192, 192, 255, 0);
  }
}

.name-transcript,
.credit {
  font-size: 16px;
  max-width: 80ch;
}

.transcript-export {
  width: 600px;
  height: 200px;
  margin: 10px;
}

#header-toggle-button {
  position: absolute;
  bottom: -40px;
  right: 20px;
  background: white;
  border: 0;
}

/* Settings Box */
.settings-box {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.hidden {
  visibility: hidden;
  height: 0;
}

#cfa-logo {
  text-align: center;
}

#cfa-logo:hover {
  opacity: .75;
}

#exit-popup {
  position: absolute;
  border: none;
  color: #555;
  background-color: transparent;
  font-size: 16px;
  right: 4px;
  top: 5px;
}

button {
  cursor: pointer;
}

/* ToDo: Add style to export box modal */

.export-box {
  margin: 0 auto;
  padding: 10px;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  position: absolute;
  width: 700px;
  height: auto;
  background-color: rgb(31, 31, 31);
  border-radius: 6px;
  color: rgb(235, 235, 235);
}


/* textarea inside the export modal */
.transcript-export {
  width: 650px;
  height: 320px;
}

.setting-button {
    height: 35px;
    box-shadow: none;
    border: none;
    border-radius: 4px;
    border: 1px solid;
    color: #165666;
    font-size: 16px;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 3px;
}

.setting-button:hover {
  border-color: #000;
}

.name-box {
  margin: auto;
  padding-top: 12px;
  padding-bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 6px;
  max-width: 90vw;
  width: 700px;
  border: 1px solid #000;
  position: absolute;
  background-color: #fff;
}

.name-box h2 {
  margin: 0;
  padding-bottom: 15px;
  font-size: 20px;
}

.dark .name-box {
  border-color: #fff;
}

.dark, .dark .interim {
  background-color: #000 !important;
  color: #fff !important;
}

.dark .name-box *:focus {
  outline: 2px solid #fff;
}


.dark #exit-popup {
  color: #ddd
}

*:focus {
  outline: 2px solid #165666;
}


/* about page */
.about-page main {
  padding: 20px;
}

header.about {
  position: relative;
}
